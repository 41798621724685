import { createRouter, createWebHistory } from 'vue-router';
import User from '../views/User.vue';
import Login from '../views/Login.vue';
import RegisterManeger from '../views/RegisterManeger.vue'
import ResetPasswordManager from '../views/ResetPasswordManager.vue'
import store from '../store';
import Dashboard from '../views/Dashboard.vue';
import Catalog from '../views/Catalog.vue';
import Product from '../views/Product.vue';
import VueCookies from 'vue-cookies'
import http from '../http';

const routes = [
  {
    path: '/:id',
    name: 'User',
    component: User,
    meta: {
      title: 'Ilustranext | Achievements',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'FV | Login',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordManager',
    component: ResetPasswordManager,
    meta: {
      title: 'FV | reset-password',
    },
  },
  {
    path: '/register-manager',
    name: 'RegisterManager',
    component: RegisterManeger,
    meta: {
      title: 'FV | register-manager',
    },
  },
  {
    path: '',
    component: Login,
    meta: {
      title: 'FV',
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'FV - Dashboard', requiresAuth: true, },
  },
  {
    path: '/catalog', name: 'Catalog', component: Catalog, meta: { title: 'FV - Catalog', requiresAuth: true, },
  },
  {
    path: '/product/:id', name: 'Product', component: Product, meta: { title: 'FV - Product', requiresAuth: true, },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
  const previousNearestMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  if (to.meta.requiresAuth && !store.state.user.token) {
    if ($cookies.isKey('user_token')) {
      store.state.user.token = $cookies.get('user_token');
      await http.get('/api/user', {
        headers: {
          Authorization: `Bearer ${store.state.user.token}`,
        }
      }).then((response) => {
        store.state.user.id = response.data.id;
        store.state.user.name = response.data.name;
        store.state.customer.id = response.data.companies[0].id;
        store.state.customer.name = response.data.companies[0].name;
      }).catch((error) => {
        $cookies.remove('user_token'),
          next({
            name: 'Login',
          });
      });
    }
    else {

      next({
        name: 'Login',
      });
    }
  }
  else {
    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    }
    else if (previousNearestMeta) {
      document.title = previousNearestMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    }).forEach((tag) => document.head.appendChild(tag));
  }

  return next();
});

export default router;
