<template>
    <div class="simpleDropdown" :class="{ showList: open }">
        <div class="simpleDropdown_header">
            <p class="simpleDropdown_text">{{ list[modelValue] }}</p>
            <button class="simpleDropdown_icon" @click="open = !open">
                <img
                    :src="require('@/assets/imgs/icons/chevron-down.svg')"
                    alt=""
                />
            </button>
        </div>
        <div class="simpleDropdown_list">
            <ul>
                <li
                    v-for="(item, i) in list"
                    :key="'dropdown_' + item + '_' + i"
                >
                    <Button
                        :text="item"
                        @click="select(i)"
                        :active="modelValue == i"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Button from '@/components/buttons/Button-text.vue';

export default {
  props: ['list', 'modelValue'],
  data() {
    return {
      open: false,
    };
  },
  components: {
    Button,
  },
  methods: {
    select(index) {
      this.$emit('update', index);
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.simpleDropdown {
    position: relative;
    min-width: 165px;
    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &_text {
        color: #fff;
        font-size: 15px;
        margin-right: 5px;
    }
    &_icon {
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        cursor: pointer;
    }
    &_list {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        background: #1e2127;
        border: 1px solid #141517;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        z-index: 2;
        display: none;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            max-height: 155px;
            overflow-y: scroll;
            width: fit-content;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                margin-bottom: 10px;
            }
        }
    }
}

.showList {
    .simpleDropdown_list {
        display: block;
    }
}
</style>
