<template>
    <div class="sectionBlock" v-if="file_glb && file_usdz">
        <div class="sectionBlock_header">
            <p>Play button for lazy loading</p>
        </div>
        <div class="sectionBlock_content">
            <div class="row">
                <p class="simpleSectionnText">Toggle ON Play Button:</p>
                <Toggle :value="lazyLoad" @update="setLazyLoad" />
            </div>
        </div>
    </div>
    <div class="sectionBlock" v-if="file_glb && file_usdz">
        <div class="sectionBlock_header">
            <p>Upload viewer thumbnail image</p>
        </div>
        <div class="sectionBlock_content">
            <div class="row listImages-90">
                <ButtonItemService
                    title="Upload"
                    subtitle=""
                    icon="/imgs/icons/icon_upload.svg"
                    @click="openModalUpload"
                />
                <ButtonItemServicePhoto
                    v-if="lazyLoadImage"
                    :photo="lazyLoadImage"
                    :alignCenter="true"
                    :hasAction="false"
                    :actionEmit="false"
                    :noHover="true"
                />
            </div>
        </div>
    </div>
    <div class="backgroundColorSelection backgroundColorSelection-block">
        <div class="backgroundColorSelection_header">
            <p>iFrame Code (3D Viewer)</p>
        </div>
        <div class="backgroundColorSelection_content">
            <p
                class="text-likeInput cursor-pointer"
                @click="copyTextIframeUploaded()"
                v-if="file_glb && file_usdz"
            >
                &lt;iframe
                src=&quot;https://ilustranext.com/viewer/?id_customer={{
                    customer
                }}&id_product={{ id }}&file_usdz={{ file_usdz }}&file_glb={{
                    file_glb
                }}&preset={{ preset }}&lazyload={{ lazyLoad ? 1 : 0 }}&quot;
                title=&quot;ilustranext&quot;&gt;&lt;/iframe&gt;
            </p>
            <p class="text-likeInput cursor-pointer" v-else>
                No viewer available
            </p>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
    <PopupUpload
        v-if="modalUpload.show"
        :title="'Upload Files'"
        :subTitle="'Drag and drop your image here'"
        :confirm="true"
        :cancel="true"
        :limitFiles="1"
        @confirm="emitConfirmUpload"
        @cancel="emitCancelUpload"
    />
</template>

<script>
import Toggle from '@/components/inputs/Toggle.vue';
import PopupAlert from '../common/PopupAlert.vue';
import ButtonItemService from '@/components/listItem/Icon-title-subtitle.vue';
import ButtonItemServicePhoto from '@/components/listItem/Square-photo-description-event.vue';
import http from '@/http';
import loadingController from '@/utils/loadingController';
import sleep from '@/utils/sleep';
import PopupUpload from '../common/PopupUpload.vue';

const lController = new loadingController();

export default {
  components: {
    PopupAlert,
    PopupUpload,
    Toggle,
    ButtonItemService,
    ButtonItemServicePhoto,
  },
  props: ['id', 'customer'],
  data() {
    return {
      file_glb: '',
      file_usdz: '',
      preset: 1,
      lazyLoad: false,
      lazyLoadImage: '',
      popupAlert: {
        show: false,
        title: 'Automation System',
        subTitle: 'Saving...',
        confirm: false,
        cancel: false,
        type: 'save',
      },
      modalUpload: {
        show: false,
      },
    };
  },
  methods: {
    getDataAutomation() {
      // $route.params.id
      http
        .get(
          `/gets/product/automation/3dviewer/links?id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          if (r.data.success) {
            if (r.data.file) {
              this.file_glb = r.data.file.file_glb;
              this.file_usdz = r.data.file.file_usdz;
              this.preset = r.data.file.preset;
              if (r.data.imageLazyLoad) this.lazyLoadImage = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.imageLazyLoad}`;
              this.emitUpdateViewer();
            }
          }
          this.completeTaskLoad();
        })
        .catch((r) => {
          debugger;
          console.log('Error:');
          console.log(r);
        });
    },
    copyTextIframeUploaded() {
      navigator.clipboard.writeText(
        `<iframe src="${this.getUrlIframe()}" title="ilustranext"></iframe>`,
      );
      this.popupAlert.title = 'Copied';
      this.popupAlert.subTitle = 'The text has been copied to the clipboard';
      this.popupAlert.confirm = true;
      this.popupAlert.cancel = false;
      this.popupAlert.show = true;
    },
    async completeTaskLoad() {
      lController.completeTask();
      this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
      if (lController.getStatus() === 'ended') {
        await sleep(1000);
        this.popupAlert.show = false;
      }
    },
    getUrlIframe() {
      const domain = 'https://ilustranext.com/';
      return `${domain}/viewer/?id_customer=${this.customer}&id_product=${this.id}&file_usdz=${
        this.file_usdz
      }&file_glb=${this.file_glb}&preset=${this.preset}&lazyload=${this.lazyLoad ? 1 : 0}`;
    },
    emitUpdateViewer() {
      this.$emit('update', this.getUrlIframe());
    },
    emitConfirm() {
      this.popupAlert.show = false;
    },
    setLazyLoad(value) {
      this.lazyLoad = value;
      this.emitUpdateViewer();
    },
    openModalUpload() {
      this.modalUpload.show = true;
    },
    async emitConfirmUpload(files) {
      this.modalUpload.show = false;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Uploading...';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.subTitle = '';

      for (let i = 0; i < files.length; i += 1) {
        this.popupAlert.subTitle = `Uploading ${i + 1} of ${files.length} files`;
        const result = await this.postImageUpload(files[i]);
        if (!result.success) {
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = result.message;
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'error';
          return;
        }
      }
      this.popupAlert.subTitle = 'Upload complete';
      this.popupAlert.confirm = true;
      this.popupAlert.type = 'upload';
      this.emitUpdateViewer();
      /* eslint-enable */
    },
    emitCancelUpload() {
      this.modalUpload.show = false;
    },
    async postImageUpload(file) {
      const response = {
        success: false,
        message: '',
      };
      // const filesSend = [];
      // filesSend.push(file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id_product', this.id);
      formData.append('id_customer', this.customer);
      await http
        .post('/posts/product/viewer/imagelazyload/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          debugger;
          console.log(r);
          response.success = r.data.success;
          response.message = r.data.message;
          if (r.data.success) {
            this.lazyLoadImage = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path}`;
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
          response.success = false;
          response.message = r.response.data.message;
          debugger;
        });
      return response;
    },
  },
  mounted() {
    this.popupAlert.title = 'Loading Product';
    this.popupAlert.subTitle = 'Please wait. completed 0%';
    this.popupAlert.show = true;
    lController.start(1);
    this.getDataAutomation();
  },
};
</script>

<style lang="scss" scoped>
.backgroundColorSelection {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    .backgroundColorSelection_header {
        padding: 11px 20px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    .backgroundColorSelection_content {
        padding: 14px 20px;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                cursor: pointer;
                border: 2px solid rgba(#0092ff, 0);
                transition: 0.5s ease;
                &:hover {
                    border: 2px solid rgba(#0092ff, 1);
                }
            }
            .none {
                background: url("/background-colors-none.png");
            }
            .active {
                border: 2px solid rgba(#0092ff, 1);
            }
        }
    }

    .colorPicker {
        position: absolute;
        top: 90px;
        right: 20px;
        z-index: 9;
    }
    &_content {
        &_buttons {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 1020px) {
                justify-content: center;
                .button {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            .button {
                background: #3d424e;
                &:hover {
                    background: #0092ff;
                }
            }
        }
        .active {
            background: #0092ff;
        }
        &_slider {
            display: flex;
            align-items: center;
            margin-top: 10px;

            > p {
                background: #3d424e;
                border: none;
                border-radius: 5px;
                width: 72px;
                font-size: 15px;
                color: #ffffff;
                text-align: center;
                padding: 6px;
                margin: 0;
                margin-left: 10px;
            }
            .inputSlider {
                position: relative;
                > input {
                    background: #3d424e;
                    border: none;
                    border-radius: 5px;
                    width: 72px;
                    font-size: 15px;
                    color: #ffffff;
                    text-align: center;
                    padding: 6px;
                    margin: 0;
                    margin-left: 10px;
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    font-size: 15px;
                    color: #ffffff;
                }
            }
        }

        &-flexList {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > ul {
                flex: 1;
                margin-right: 20px;
            }
            .button {
                min-width: auto;
            }
        }
    }
    &-block {
        width: 100%;
    }
}

.text-likeInput {
    background: #3d424e;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: #ffffff;
    padding: 6px;
    margin: 0;
    white-space: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}
</style>
