<template>
    <div
        class="toggle"
        :class="{ 'toggle-on': value }"
        @click.self="toggleValue()"
    >
        <div class="toggle_button" @click="toggleValue"></div>
    </div>
</template>

<script>
export default {
  props: ['value'],
  methods: {
    toggleValue() {
      this.$emit('update', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
    width: 40px;
    height: 20px;
    border-radius: 50px;
    background: #3d424e;
    border: 1px solid #666972;
    display: flex;
    align-items: center;
    padding: 1px;
    transition: 0.5s ease-out;
    cursor: pointer;
    position: relative;
    &_button {
        transition: 0.5s ease-out;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #e0e0e0;
        position: absolute;
        left: 1px;
    }
    &-on {
        background: #0264d8;
        border: 1px solid #3c81d3;
        .toggle_button {
            left: calc(100% - 17px);
        }
    }
}
</style>
