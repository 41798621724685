<template>
    <div class="slider">
        <input
            type="range"
            :min="min ? min : 0"
            :max="max ? max : 100"
            :value="myValue.toString().replace('%', '')"
            class="slider_input"
            @input="updateValue"
            :disabled="!enabled"
        />
    </div>
</template>

<script>
export default {
  props: ['myValue', 'enabled', 'min', 'max'],
  data() {
    return {};
  },
  methods: {
    updateValue(event) {
      this.$emit('update', event.target.value);
    },
  },
  created() {
    // this.updateValue();
  },
};
</script>

<style lang="scss" scoped>
.slider {
    width: 100%;
    &_input {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 4px;
        background: #ffffff;
        outline: none;
        border-radius: 6px;
        border: 1px solid #3d424e;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background: #0264d8;
            border-radius: 50%;
            cursor: pointer;
            border: 2px solid #ffffff;
            box-shadow: 0px 0px 0px 2px #d8d8d8;
        }
    }
}
</style>
