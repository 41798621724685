<template>
    <button
        class="button"
        :class="{
            active: active,
            inverted: inverted,
            textCenter: noicon,
            smallspace: smallspace,
            notext: !text,
        }"
        :style="{ background: background ? background : '' }"
    >
        <div class="button_image" :class="{ notext: !text }" v-if="!noicon">
            <img :src="require('@/assets' + icon)" alt="" />
        </div>
        <p class="button_text">{{ text }}</p>
    </button>
</template>

<script>
export default {
  props: ['text', 'icon', 'active', 'inverted', 'noicon', 'smallspace', 'background'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.button {
    padding: 10px 13px;
    background: none;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
    min-width: 160px;

    &_image {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
        transition: 0.5s ease;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &_text {
        font-size: 13px;
        font-weight: bold;
        color: #95949b;
        transition: 0.5s ease;
        white-space: nowrap;
    }

    &.notext {
        padding: 10px;
        .button_image {
            margin: 0;
        }
    }

    &:hover {
        background: #0092ff !important;
        .button {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
    }

    @at-root {
        .active #{&} {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
        .active {
            background: #0092ff !important;
        }
    }
    &.inverted {
        .button_image {
            order: 1;
            margin-right: 0;
            margin-left: 20px;
        }
        .button_text {
            order: 0;
        }
    }
}
.textCenter {
    justify-content: center;
}
.smallspace {
    padding: 5px 10px;
    .button_image {
        margin-right: 5px;
    }
    &.inverted {
        .button_image {
            margin-left: 5px;
        }
    }
}
</style>
