<template>
    <div class="popupAlert">
        <div class="popupAlert_content">
            <div
                class="popupAlert_content_text"
                :class="{ fullBorder: !confirm && !cancel }"
            >
                <p class="popupAlert_content_text_title">{{ title }}</p>
                <p class="popupAlert_content_text_subTitle">
                    {{ subTitle }}
                </p>
            </div>
            <div class="popupAlert_content_buttons" v-if="confirm || cancel">
                <button
                    class="popupAlert_content_buttons_button cancel"
                    v-if="cancel"
                    @click="cancelEvent"
                    :class="{ fullButton: !confirm }"
                >
                    <img :src="require('@/assets/imgs/icons/x.svg')" alt="" />
                </button>
                <button
                    class="popupAlert_content_buttons_button confirm"
                    v-if="confirm"
                    @click="confirmEvent"
                    :class="{ fullButton: !cancel }"
                >
                    <img
                        :src="require('@/assets/imgs/icons/check.svg')"
                        alt=""
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['title', 'subTitle', 'confirm', 'cancel'],
  methods: {
    confirmEvent() {
      this.$emit('confirm');
    },
    cancelEvent() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang='scss' scoped>
.popupAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    &_content {
        min-width: 650px;
        min-height: 300px;
        display: flex;

        &_text {
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background: #141517;
            border-radius: 15px 0 0 15px;
            width: -webkit-fill-available;
            //height: 100%;

            &_title {
                font-size: 24px;
                font-weight: bold;
                color: #fff;
            }
            &_subTitle {
                font-size: 14px;
                font-weight: lighter;
                color: #fff;
            }
        }
        &_buttons {
            display: flex;
            flex-direction: column;
            width: 70px;
            background: linear-gradient(90deg, #141517 0%, #252525 100%);
            border-radius: 0 15px 15px 0;

            &_button {
                width: 100%;
                height: 50%;
                border: none;
                cursor: pointer;
                transition: 0.5s ease;
                > img {
                    width: 20px;
                    height: 20px;
                }

                &:first-child {
                    border-radius: 0 15px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 15px 0;
                }
            }
        }
    }
    .confirm {
        background-color: rgba($color: #0fc621, $alpha: 0);
        &:hover {
            background-color: rgba($color: #0fc621, $alpha: 1);
        }
    }
    .cancel {
        background-color: rgba($color: #ed1802, $alpha: 0);
        &:hover {
            background-color: rgba($color: #ed1802, $alpha: 1);
        }
    }

    .fullButton {
        height: 100%;
        border-radius: 0 15px 15px 0;
    }
    .fullBorder {
        border-radius: 15px 15px 15px 15px;
    }
}
</style>
