<template>
    <div class="productScreen_section">
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">Tailor Files</p>
        </div>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollAutomationSystem', 'left')"
                v-if="scrollAutomationSystemAux.scrollX > 0"
            />
            <ul
                @scroll="verifyScrollAutomationSystem"
                id="scrollAutomationSystem"
            >
                <li
                    class="productScreen_servicesList_item"
                    v-for="(file, i) in automationFiles"
                    :key="'imagesUploadedSilo_' + i"
                >
                    <ButtonItemServicePhoto
                        :photo="photo"
                        :active="
                            selected.file == file &&
                            selected.type == 'automation'
                        "
                        :alignCenter="true"
                        flag="A"
                        flagColor="#7d35ff"
                        :hasAction="false"
                        :actionEmit="false"
                        :actionIcon="'/imgs/icons/trash.svg'"
                        @click="selectFile(file, 'automation')"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollAutomationSystem', 'right')"
                v-if="
                    scrollAutomationSystemAux.scrollX < 2 &&
                    scrollAutomationSystemAux.scrollX > -1
                "
            />
        </div>
    </div>
    <div class="productScreen_section">
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">Files Uploaded</p>
        </div>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollImagemUploaded', 'left')"
                v-if="scrollImageUploadedAux.scrollX > 0"
            />
            <ul @scroll="verifyScrollImagemUploaded" id="scrollImagemUploaded">
                <li class="productScreen_servicesList_item">
                    <ButtonItemService
                        title="Upload"
                        subtitle=""
                        icon="/imgs/icons/icon_upload.svg"
                        @click="openModalUpload"
                    />
                </li>
                <li
                    class="productScreen_servicesList_item"
                    v-for="(file, i) in files"
                    :key="'imagesUploadedSilo_' + i"
                >
                    <ButtonItemServicePhoto
                        :photo="photo"
                        :active="
                            selected.file == file &&
                            selected.type == 'fileUploaded'
                        "
                        :alignCenter="true"
                        :hasAction="false"
                        :actionEmit="false"
                        :actionIcon="'/imgs/icons/trash.svg'"
                        @click="selectFile(file, 'fileUploaded')"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollImagemUploaded', 'right')"
                v-if="
                    scrollImageUploadedAux.scrollX < 2 &&
                    scrollImageUploadedAux.scrollX > -1
                "
            />
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
    <PopupUpload
        v-if="modalUpload.show"
        :title="'Upload Files'"
        :subTitle="'Drag and drop your image here'"
        :confirm="true"
        :cancel="true"
        @confirm="emitConfirmUpload"
        @cancel="emitCancelUpload"
    />
</template>

<script>
import ButtonIcon from '@/components/buttons/Button-icon-text.vue';
import ButtonBorderIcon from '@/components/buttons/Button-icon-border.vue';
import ButtonItemService from '@/components/listItem/Icon-title-subtitle.vue';
import PopupUpload from '../common/PopupUpload.vue';
import http from '@/http';
import PopupAlert from '../common/PopupAlert.vue';
import loadingController from '@/utils/loadingController';
import sleep from '@/utils/sleep';
import ButtonItemServicePhoto from '@/components/listItem/Square-photo-description-event.vue';

const lController = new loadingController();

export default {
  components: {
    ButtonIcon,
    ButtonBorderIcon,
    ButtonItemService,
    PopupAlert,
    PopupUpload,
    ButtonItemServicePhoto,
  },
  props: ['id', 'customer', 'photo'],
  data() {
    return {
      files: [],
      automationFiles: [],
      requestedService: false,
      selected: {
        file: null,
        type: null,
      },
      scrollImageUploadedAux: {
        scrollX: 0,
      },
      scrollAutomationSystemAux: {
        scrollX: 0,
      },
      popupAlert: {
        show: false,
        title: 'Automation System',
        subTitle: 'Saving...',
        confirm: false,
        cancel: false,
        type: 'save',
      },
      modalUpload: {
        show: false,
      },
    };
  },
  methods: {
    sendTailorRequest() {
      if (!this.requestedService) {
        http
          .post('/posts/email/request/service/tailor/send', {
            id_product: this.id,
            id_customer: this.customer,
            page: 'Video',
          })
          .then((r) => {
            if (r.data.success) {
              this.popupAlert.subTitle = 'Request sent! We will get in touch with you soon';
              this.popupAlert.title = 'Tailor Request';
              this.popupAlert.confirm = true;
              this.popupAlert.type = 'request';
              this.popupAlert.show = true;
              const data = new Date();
              this.requestedService = true;
              localStorage.setItem(
                'video_request',
                `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`,
              );
            } else {
              this.popupAlert.subTitle = `Error: ${r.data.message}`;
              this.popupAlert.confirm = true;
            }
          })
          .catch((r) => {
            console.log('Error:');
            console.log(r);
          });
      }
    },
    defineTailorRequest() {
      const value = localStorage.getItem('video_request');
      if (value != null) {
        const data = new Date(value);
        const now = new Date();
        const dCompare = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        if (data >= dCompare) {
          this.requestedService = true;
        }
      }
      this.completeTaskLoad();
    },
    getAutomationFiles() {
      // $route.params.id
      http
        .get(
          `/gets/product/automation/videos/links?id_product=${this.$route.params.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          console.log(r.data);
          if (r.data.success) {
            r.data.files.forEach((file) => {
              const namesplit = file.split('_');
              let extensionFile = file.split('.').pop();
              if (namesplit.length > 2) extensionFile = namesplit.pop().split('.')[0];
              this.automationFiles.push({
                link: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/${file}`,
                extensionFile,
              });
            });
            if (this.automationFiles.length > 0) {
              this.selectFile(this.automationFiles[0], 'automation');
            }
            // this.getDataInfos();
          }
          this.completeTaskLoad();
          setTimeout(() => {
            this.verifyScrollImagemUploaded();
            this.verifyScrollAutomationSystem();
          }, 100);
        })
        .catch((r) => {
          console.log('Error:');
          /* eslint-disable*/
          debugger;
          /* eslint-enable */
          console.log(r.response);
          // this.$router.push('/catalog');
        });
    },
    getUploadsFiles() {
      // $route.params.id
      http
        .get(
          `/gets/product/images_uploaded/videos?id_product=${this.$route.params.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          if (r.data.success) {
            r.data.files.forEach((file) => {
              const extensionFile = file.split('.').pop();
              this.files.push({
                link: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/${file}`,
                extensionFile,
              });
            });
            // this.getDataInfos();
          }
          this.completeTaskLoad();
          setTimeout(() => {
            this.verifyScrollImagemUploaded();
            this.verifyScrollAutomationSystem();
          }, 100);
        })
        .catch((r) => {
          console.log('Error:');
          /* eslint-disable*/
          debugger;
          /* eslint-enable */
          console.log(r.response);
          // this.$router.push('/catalog');
        });
    },
    openModalUpload() {
      this.modalUpload.show = true;
    },
    /* eslint-disable */
    async emitConfirmUpload(files) {
      this.modalUpload.show = false;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Uploading...';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.subTitle = '';

      for (let i = 0; i < files.length; i += 1) {
        this.popupAlert.subTitle = `Uploading ${i + 1} of ${files.length} files`;
        const result = await this.postImageUpload(files[i]);
        if (!result.success) {
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = result.message;
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'error';
          return;
        }
      }
      this.popupAlert.subTitle = 'Upload complete';
      this.popupAlert.confirm = true;
      this.popupAlert.type = 'upload';

      /* eslint-enable */
    },
    emitCancelUpload() {
      this.modalUpload.show = false;
    },
    async postImageUpload(file) {
      const response = {
        success: false,
        message: '',
      };
      // const filesSend = [];
      // filesSend.push(file);
      const formData = new FormData();
      formData.append('file_image', file);
      formData.append('id_product', this.id);
      formData.append('id_customer', this.customer);
      await http
        .post('/posts/product/images-uploaded/videos/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          debugger;
          console.log(r);
          response.success = r.data.success;
          response.message = r.data.message;
          if (r.data.success) {
            // this.uploadImages.push({
            //  photo: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path_image}`,
            // });
            const extensionFile = r.data.path_image.split('.').pop();
            this.files.push({
              link: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path_image}`,
              extensionFile,
            });
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
          response.success = false;
          response.message = r.response.data.message;
          debugger;
        });
      return response;
    },
    emitConfirm() {
      if (this.popupAlert.type === 'deleteAutomation') {
        this.deleteDataAutomation(this.selected);
      } else if (this.popupAlert.type === 'save') {
        this.popupAlert.confirm = false;
        this.popupAlert.show = false;
      } else {
        this.popupAlert.confirm = false;
        this.popupAlert.show = false;
      }
    },
    emitCancel() {
      this.popupAlert.show = false;
    },
    async completeTaskLoad() {
      lController.completeTask();
      this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
      if (lController.getStatus() === 'ended') {
        await sleep(1000);
        this.popupAlert.show = false;
      }
    },
    selectFile(file, type) {
      this.selected.file = file;
      this.selected.type = type;
      this.$emit('update', file.link);
    },
    downloadFile(file) {
      window.open(file.link, '_blank');
    },
    verifyScrollImagemUploaded() {
      const element = document.getElementById('scrollImagemUploaded');
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollImageUploadedAux.scrollX = -1;
      else if (scrollX === 0) this.scrollImageUploadedAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollImageUploadedAux.scrollX = 2;
      else this.scrollImageUploadedAux.scrollX = 1;
    },
    verifyScrollAutomationSystem() {
      const element = document.getElementById('scrollAutomationSystem');
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollAutomationSystemAux.scrollX = -1;
      else if (scrollX === 0) this.scrollAutomationSystemAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollAutomationSystemAux.scrollX = 2;
      else this.scrollAutomationSystemAux.scrollX = 1;
    },
    /* eslint-disable no-param-reassign */
    scrollElement(id, direction) {
      const element = document.getElementById(id);
      if (direction === 'left') {
        element.scrollBy({
          left: -100,
          behavior: 'smooth',
        });
      } else {
        element.scrollBy({
          left: 100,
          behavior: 'smooth',
        });
      }
    },
    /* eslint-enable no-param-reassign */
  },
  mounted() {
    this.popupAlert.title = 'Loading Product';
    this.popupAlert.subTitle = 'Please wait. completed 0%';
    this.popupAlert.show = true;
    lController.start(2);
    this.getUploadsFiles();
    this.getAutomationFiles();
    this.verifyScrollImagemUploaded();
    this.verifyScrollAutomationSystem();
    window.addEventListener('resize', () => {
      this.verifyScrollImagemUploaded();
      this.verifyScrollAutomationSystem();
    });
  },
};
</script>

<style lang="scss" scoped>
.productScreen_servicesList {
    background: #2e3238;
    .buttonScroll {
        background: #2e3238;
        z-index: 2;
    }
    .button {
        &_left {
            border-right-color: #141517;
        }
        &_right {
            border-left-color: #141517;
        }
    }
}

.backgroundColorSelection {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    &_container {
        position: relative;
        .button {
            position: absolute;
            z-index: 1;
            height: 100%;
            top: 0;
            display: flex;
            align-items: center;
            background: #2e3238;
            border-top: none;
            &_left {
                left: 0;
                border-radius: 10px 0 0 10px;
                border-right: 1px solid #373c45;
            }
            &_right {
                right: 0;
                border-radius: 0 10px 10px 0;
                border-left: 1px solid #373c45;
            }
            &:hover {
                background: #0264d8;
            }
        }
    }
    .backgroundColorSelection_header {
        padding: 11px 20px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    .backgroundColorSelection_content {
        padding: 14px 20px;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                cursor: pointer;
                border: 2px solid rgba(#0092ff, 0);
                transition: 0.5s ease;
                &:hover {
                    border: 2px solid rgba(#0092ff, 1);
                }
            }
            .none {
                background: url("/background-colors-none.png");
            }
            .active {
                border: 2px solid rgba(#0092ff, 1);
            }
        }
    }
    &_content {
        &_buttons {
            display: flex;
            justify-content: space-between;
            .button {
                background: #3d424e;
                &:hover {
                    background: #0092ff;
                }
            }
        }
        .active {
            background: #0092ff;
        }
        &_slider {
            display: flex;
            align-items: center;
            margin-top: 10px;

            > p {
                background: #3d424e;
                border: none;
                border-radius: 5px;
                width: 72px;
                font-size: 15px;
                color: #ffffff;
                text-align: center;
                padding: 6px;
                margin: 0;
                margin-left: 10px;
            }
        }
    }
    &-block {
        width: 100%;
    }
    &_images {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .productScreen_servicesList_item {
            &:last-child {
                margin-right: 12px;
            }
        }
    }
}
</style>
