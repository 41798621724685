<template>
    <div class="menu">
        <div class="menu_top">
            <div class="user">
                <div class="photo">
                    <img :src="photo" />
                </div>
                <p class="user_name">{{ name }}</p>
                <p class="user_company">{{ company }}</p>
            </div>
            <nav class="menu_list">
                <ul>
                    <li
                        class="menu_list_item"
                        v-for="item in itens"
                        :key="item.name"
                    >
                        <ButtonMenu
                            :text="item.name"
                            :icon="item.icon"
                            :active="active == item.name.toLowerCase()"
                            @click="toPage(item.url)"
                        />
                    </li>
                </ul>
            </nav>
        </div>
        <div class="menu_bottom">
            <div class="menu_list_item">
                <ButtonMenu
                    text="Report Bug"
                    icon="/imgs/icons/tool.svg"
                    @click="openReportBug()"
                />
            </div>
            <div class="menu_list_item">
                <ButtonMenu
                    text="Logout"
                    icon="/imgs/icons/log-out.svg"
                    @click="logout()"
                />
            </div>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
    />
    <PopupReportBug
        v-if="popupAlertBug.show"
        :title="popupAlertBug.title"
        :subTitle="popupAlertBug.subTitle"
        :confirm="popupAlertBug.confirm"
        :cancel="popupAlertBug.cancel"
        @confirm="alertEmitConfirm"
        @cancel="alertEmitCancel"
    />
</template>

<script>
import ButtonMenu from '@/components/buttons/Button-icon-text.vue';
import store from '@/store';
import PopupReportBug from '@/views/common/PopupReportBug.vue';
import PopupAlert from './PopupAlert.vue';
import http from '@/http';

export default {
  props: ['active'],
  components: {
    ButtonMenu,
    PopupReportBug,
    PopupAlert,
  },
  data() {
    return {
      name: 'John Doe',
      company: 'Company Inc.',
      photo: 'https://ilustranext.com',
      itens: [
        {
          icon: '/imgs/icons/home.svg',
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          icon: '/imgs/icons/layers.svg',
          name: 'Catalog',
          url: '/catalog',
        },
      ],
      popupAlert: {
        show: false,
        title: 'Automation System',
        subTitle: 'Saving...',
        confirm: false,
        cancel: false,
        type: 'save',
      },
      popupAlertBug: {
        show: false,
        title: 'Bug Report',
        subTitle: '',
        confirm: false,
        cancel: false,
        type: 'save',
      },
    };
  },
  methods: {
    toPage(url) {
      this.$router.push(url);
    },
    logout() {
      this.$cookies.remove('user_token');
      store.state.user.token = '';
      this.$router.push('/');
    },
    openReportBug() {
      this.popupAlertBug.show = true;
      this.popupAlertBug.confirm = true;
      this.popupAlertBug.cancel = true;
    },
    emitConfirm() {
      this.popupAlert.show = false;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
    },
    alertEmitCancel() {
      this.popupAlertBug.show = false;
    },
    alertEmitConfirm(file, subject, description) {
      this.popupAlertBug.show = false;
      this.popupAlert.show = true;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.subTitle = 'Sending...';
      this.popupAlert.title = 'Bug Report';

      const formData = new FormData();
      formData.append('id_user', store.state.user.id);
      formData.append('id_customer', store.state.customer.id);
      formData.append('subject', subject);
      formData.append('description', description);
      formData.append('url', window.location.href);
      debugger;
      if (file.length > 0) formData.append('file', file[0]);
      http
        .post('/api/report-bug', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          if (r.data.success) {
            this.popupAlert.subTitle = 'Bug report sent successfully!';
          } else {
            this.popupAlert.subTitle = `Error sending bug report: ${r.data.message}`;
          }
          this.popupAlert.confirm = true;
        })
        .catch((r) => {
          this.popupAlert.subTitle = 'Error sending bug report!';
          this.popupAlert.confirm = true;
          debugger;
        });
    },
  },
  mounted() {
    const name = store.state.user.name.split(' ');
    this.name = name[0];
    if (name.length > 1) {
      this.name += ` ${name[name.length - 1]}`;
    }
    this.company = store.state.customer.name;
    this.photo = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/users/customers/${store.state.customer.id}.png`;
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.menu {
    display: flex;
    height: 100vh;
    background: #272a30;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    padding-top: 37px;
    position: sticky;
    top: 0;
    //width: 200px;
    &_top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_list {
        margin-top: 37px;
        &_item {
            margin-bottom: 12px;
        }
    }
}
.photo {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #3d424e;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #3d424e;

    &_name {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        margin-top: 16px;
    }
    &_company {
        font-size: 12px;
        color: #95949b;
        margin-top: 4px;
        margin-bottom: 20px;
    }
}
</style>
