import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      id: '',
      name: '',
      token: '',
    },
    customer: {
      id: '',
      name: '',
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
