<template>
    <p class="productScreen_sku">
        {{ collection ? collection + " Collection" : "" }} ({{
            sku ? "SKU: " + sku : ""
        }}
        #{{ id }})
    </p>
    <h1 class="productScreen_name">{{ name ? name : sku }}</h1>
    <ul class="productScreen_productinfo">
        <li
            class="productScreen_productinfo_item"
            :class="{ open: category.open }"
            :key="'description_category'"
        >
            <div class="productScreen_productinfo_item_header">
                <div class="productScreen_productinfo_item_header_title">
                    <p>Categories</p>
                </div>
                <ul class="productScreen_productinfo_item_header_icons">
                    <li
                        class="productScreen_productinfo_item_header_icons_icon"
                        @click="openCloseCategory(!category.edit)"
                    >
                        <img
                            :src="require('@/assets/imgs/icons/edit.svg')"
                            alt=""
                        />
                    </li>
                    <li
                        @click="category.open = !category.open"
                        class="
                            productScreen_productinfo_item_header_icons_icon
                            icon_arrow
                        "
                    >
                        <img
                            :src="
                                require('@/assets/imgs/icons/comp-card-btn.svg')
                            "
                            alt=""
                        />
                    </li>
                </ul>
            </div>
            <div class="productScreen_productinfo_item_value">
                <ul class="listTag" :class="{ 'listTag-edit': category.edit }">
                    <li
                        v-for="(tag, i) in category.tags"
                        :key="'tag_' + tag.id"
                    >
                        <ButtonIcon
                            :text="tag.name"
                            :noicon="!category.edit"
                            icon="/imgs/icons/trash.svg"
                            :inverted="true"
                            @click="removeCategory(i)"
                            :smallspace="true"
                        />
                    </li>
                </ul>
            </div>
            <div
                class="
                    productScreen_productinfo_item_header_title
                    divCategories
                "
                v-if="category.edit"
            >
                <p v-show="category.open">Add Categories</p>
            </div>
            <div
                class="productScreen_productinfo_item_value"
                v-if="category.edit"
            >
                <ul class="listTag listTag-add">
                    <li
                        v-for="tag in filterAddCategories"
                        :key="'tagnew_' + tag.id"
                    >
                        <ButtonIcon
                            :text="tag.name"
                            :noicon="!category.edit"
                            icon="/imgs/icons/plus.svg"
                            :inverted="true"
                            :smallspace="true"
                            @click="addCategory(tag)"
                        />
                    </li>
                </ul>
            </div>
        </li>
        <li
            class="productScreen_productinfo_item"
            :class="{ open: info.open }"
            v-for="(info, i) in infos"
            :key="'description_' + i"
        >
            <div class="productScreen_productinfo_item_header">
                <div class="productScreen_productinfo_item_header_title">
                    <input
                        type="text"
                        v-model="info.title"
                        :disabled="!info.edit"
                        :class="{ inEdit: info.edit }"
                        @change="changeTitle($event, info)"
                    />
                </div>
                <ul class="productScreen_productinfo_item_header_icons">
                    <li
                        class="productScreen_productinfo_item_header_icons_icon"
                    >
                        <img
                            :src="require('@/assets/imgs/icons/edit.svg')"
                            @click="editOpenCloseInfo(info, !info.edit)"
                        />
                    </li>
                    <li
                        @click="info.open = !info.open"
                        class="
                            productScreen_productinfo_item_header_icons_icon
                            icon_arrow
                        "
                    >
                        <img
                            :src="
                                require('@/assets/imgs/icons/comp-card-btn.svg')
                            "
                            alt=""
                        />
                    </li>
                    <li
                        class="productScreen_productinfo_item_header_icons_icon"
                        @click="deleteInfo(i, info)"
                    >
                        <img
                            :src="
                                require('@/assets/imgs/icons/trash-button.svg')
                            "
                            alt=""
                        />
                    </li>
                </ul>
            </div>
            <div class="productScreen_productinfo_item_value">
                <!-- eslint-disable max-len -->
                <textarea
                    class="textarea"
                    :class="{ inEdit: info.edit }"
                    :style="{ height: info.height + 'px' }"
                    @input="heightTextArea($event, info)"
                    @change="changeTextArea($event, info)"
                    v-model="info.value"
                    :id="'textarea_' + i"
                    :disabled="!info.edit"
                ></textarea>
                <!-- eslint-enable max-len -->
            </div>
        </li>
    </ul>
    <div
        class="productScreen_productinfo_buttonAddMoreDescription"
        @click="addinfo()"
    >
        <img :src="require('@/assets/imgs/icons/add-items.svg')" alt="" />
    </div>
</template>

<script>
import axios from 'axios';
import ButtonIcon from '@/components/buttons/Button-icon-text.vue';
import http from '@/http';

export default {
  components: {
    ButtonIcon,
  },
  props: ['name', 'sku', 'id', 'collection', 'infos', 'customer', 'tags'],
  data() {
    return {
      allCategories: [],
      category: {
        open: false,
        edit: false,
        tags: [],
      },
    };
  },
  methods: {
    getDataCategories() {
      http
        .get('/gets/product/categories/show')
        .then((r) => {
          if (r.data.success) {
            r.data.categories.forEach((cat) => {
              cat.subcategories.forEach((subcat) => {
                this.allCategories.push({
                  name: subcat.subcategory,
                  id: subcat.id,
                });
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* eslint-disable no-param-reassign */
    heightTextArea(event, info) {
      info.height = 0;
      info.height = event.target.scrollHeight;
    },
    startSetAllTextAreasHeight() {
      this.infos.forEach((info, i) => {
        info.height = 0;
        const textarea = document.getElementById(`textarea_${i}`);
        textarea.style.height = '';
        info.height = textarea.scrollHeight;
      });
    },
    editOpenCloseInfo(info, value) {
      info.open = true;
      info.edit = value;
      setTimeout(() => {
        this.startSetAllTextAreasHeight();
      }, 500);
    },
    updateDbInfo(title, value, info) {
      if (title.trim() && value.trim()) {
        if (info.id === 0) {
          http
            .post('/posts/product/description/store', {
              id_product: this.id,
              id_customer: this.customer,
              title: title.trim(),
              notes: value.trim(),
            })
            .then((r) => {
              if (r.data.success) {
                info.id = r.data.id_product_description;
                console.log(r.data);
              } else {
                console.log(r.data);
                console.log({
                  id_product: this.id,
                  id_customer: this.customer,
                  title: title.trim(),
                  notes: value.trim(),
                });
              }
            })
            .catch((r) => {
              console.log(r);
            });
        } else {
          http
            .put('/puts/product/description/edit', {
              id_product_description: info.id,
              id_product: this.id,
              id_customer: this.customer,
              title,
              notes: value,
            })
            .then((r) => {
              console.log(r);
            });
        }
      } else {
        console.log(title.trim() && value.trim(), title.trim(), value.trim());
      }
    },
    deleteInfo(index, info) {
      this.$emit('deleteInfo', index);
      http
        .delete(
          `/deletes/product/description/delete?id_product_description=${info.id}&id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* eslint-enable no-param-reassign */
    openCloseCategory(value) {
      this.category.open = true;
      this.category.edit = value;
    },
    addCategory(tag, request = true) {
      this.category.tags.push({
        name: tag.name,
        id: tag.id,
      });
      if (request) {
        http
          .post('/posts/product/subcategories/store', {
            id_product: this.id,
            id_customer: this.customer,
            id_product_subcategory: tag.id,
          })
          .then((r) => {
            console.log(r.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    addinfo() {
      this.$emit('addinfo');
    },
    removeCategory(index) {
      if (this.category.edit) {
        const removed = this.category.tags.splice(index, 1);
        console.log(`Removed: ${removed}`);
        http
          .delete(
            `/deletes/product/subcategories/delete?id_product=${this.id}&id_customer=${this.customer}&id_product_subcategory=${removed[0].id}`,
          )
          .then((r) => {
            console.log(r.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    changeTextArea(event, info) {
      this.updateDbInfo(info.title, event.target.value.trim(), info);
    },
    changeTitle(event, info) {
      this.updateDbInfo(event.target.value.trim(), info.value, info);
    },
  },
  computed: {
    filterAddCategories() {
      return this.allCategories.filter((cat) => !this.category.tags.find((my) => my.id === cat.id));
    },
  },
  mounted() {
    this.startSetAllTextAreasHeight();
    this.getDataCategories();
  },
};
</script>

<style lang="scss" >
p {
    margin: 0;
}
.listTag {
    display: flex;
    flex-wrap: wrap;
    .button {
        min-width: auto;
        background: #3d424e;
        cursor: default;
        &:hover {
            background: #3d424e;
        }
    }
    > li {
        margin: 5px;
    }
    &-edit {
        .button {
            cursor: pointer;
            &:hover {
                background: #ed1802;
            }
        }
    }
    &-add {
        .button {
            cursor: pointer;
            &_image {
                //padding-left: 5px;
            }
            &:hover {
                background: #0264d8;
            }
        }
    }
}
.divCategories {
    border-top: 1px solid #3d424e;
    padding: 10px 20px;
    padding-bottom: 0;
}
.inEdit {
    background: #1e2127 !important;
    border-radius: 10px;
    padding: 10px;
}
</style>
