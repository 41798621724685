<template>
    <div class="cardReview" :class="{ needAprovation: status === 2 }">
        <div class="header">
            <h3 class="header_title">{{ name }}</h3>
            <p class="header_sv">{{ sv }}</p>
        </div>
        <div
            class="content"
            :class="'beforeColor-' + (round == 6 ? 0 : colors[status])"
        >
            <div class="content_card">
                <div class="content_top">
                    <p class="content_round">
                        {{ round ? "Round: " + round : "Not Started" }}
                    </p>
                    <div class="content_top_info">
                        <ul class="content_top_info_balls">
                            <li
                                v-for="(n, i) in getBalls()"
                                :key="'ball_a' + i"
                                :class="n"
                                style="color: #fff; font-size: 12px"
                            ></li>
                        </ul>
                        <p class="content_top_info_models">
                            {{ models }} models left
                        </p>
                    </div>
                </div>
                <div class="content_botton">
                    <div class="content_botton_group">
                        <div>
                            <p class="content_botton_group_title">
                                Upcoming dates
                            </p>
                            <p class="content_botton_group_info">
                                {{ upcoming }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="content_botton_group"
                        :class="{ invisible: status === 2 }"
                    >
                        <div>
                            <p class="content_botton_group_title">START DATE</p>
                            <p class="content_botton_group_info">
                                {{ start }}
                            </p>
                        </div>
                        <div>
                            <p class="content_botton_group_title">END DATE</p>
                            <p class="content_botton_group_info">
                                {{ end }}
                            </p>
                        </div>
                    </div>
                </div>
                <a :href="link" v-if="status === 2">
                    <Button class="button" text="Go to Review" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/buttons/Button-text-border.vue';

export default {
  props: ['name', 'sv', 'round', 'rounds', 'models', 'upcoming', 'start', 'end', 'link', 'status'],
  components: {
    Button,
  },
  data() {
    return {
      colors: {
        0: 'black',
        1: 'purple',
        2: 'blue',
        3: 'yellow',
        4: 'blueGdr',
        5: 'green',
        6: 'green',
      },
    };
  },
  methods: {
    getBalls() {
      const balls = [];
      for (let i = 0; i < this.round - 1; i += 1) {
        balls.push('ballColor-blueGdr');
      }
      if (this.round) balls.push(`ballColor-${this.colors[this.status]}`);
      for (let i = 0; i < 7 - this.round; i += 1) {
        balls.push('ballColor-black');
      }
      return balls;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.cardReview {
    width: 280px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 5px;
    &_title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
    }
    &_sv {
        font-size: 10px;
        color: #919198;
        text-transform: uppercase;
    }
}
.content {
    position: relative;
    &_card {
        padding: 20px;
        padding-bottom: 30px;
        background: #171a20;
        border-radius: 5px;
        position: relative;
        // overflow: hidden;

        //&:hover {
        //    animation: hoverElement;
        //    animation-duration: 0.5s;
        //    animation-fill-mode: forwards;
        //    //.button {
        //    //    bottom: 0;
        //    //}
        //}

        .button {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            bottom: 0px;
            background: #2a2f37;
            &:hover {
                background: #0092ff;
            }
        }
    }

    &::before {
        content: "";
        display: block;
        width: 80%;
        border-radius: 4px 4px 0 0;
        height: 6px;
        margin: 0 auto;
    }

    &_round {
        font-size: 14px;
        color: #fff;
        margin-bottom: 6px;
    }

    &_top {
        padding-bottom: 20px;
        border-bottom: 1px solid #3d424e;
        &_info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &_balls {
                display: flex;
                align-items: center;
                li {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    + li {
                        margin-left: 4px;
                    }
                }
            }
            &_models {
                font-size: 10px;
                color: #95949b;
            }
        }
    }

    &_botton {
        line-height: 16px;
        &_group {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 20px;
            &_title {
                font-size: 10px;
                color: #95949b;
            }
            &_info {
                font-size: 12px;
                color: #fff;
            }
            > div + div {
                margin-left: 26px;
            }
        }
    }
    .needAprovation {
        .content_card {
            background: #2a2f37;
        }
    }
}
.invisible {
    opacity: 0;
}

.ballColor {
    &-grey {
        background: #919198;
    }
    &-purple {
        background: #7d35ff;
    }
    &-black {
        background: #3d424e;
    }
    &-blue {
        background: #0264d8;
    }
    &-blueGdr {
        background: linear-gradient(
            180deg,
            #4235e4 0%,
            #3d2ee8 0.01%,
            #06005b 100%
        );
    }
    &-red {
        background: #ed1802;
    }
    &-yellow {
        background: #ffe433;
    }
    &-green {
        background: #0fc621;
    }
}
.beforeColor {
    &-grey {
        &::before {
            background: #919198;
        }
    }
    &-purple {
        &::before {
            background: #7d35ff;
        }
    }
    &-black {
        &::before {
            background: #3d424e;
        }
    }
    &-blue {
        &::before {
            background: #0264d8;
        }
    }
    &-red {
        &::before {
            background: #ed1802;
        }
    }
    &-yellow {
        &::before {
            background: #ffe433;
        }
    }
    &-green {
        &::before {
            background: #0fc621;
        }
    }
}

@keyframes hoverElement {
    0% {
        overflow: hidden;
    }
    99% {
        overflow: hidden;
    }
    100% {
        overflow: visible;
    }
}
</style>
