<template>
    <div class="button" :class="{ bFont: bigFont }">
        <p>{{ text }}</p>
    </div>
</template>

<script>
export default {
  props: ['text', 'bigFont'],
};
</script>

<style lang="scss" scoped>
.button {
    padding: 15px;
    background: #171a20;
    border-radius: 0 0 5px 5px;
    text-align: center;
    border-top: 1px solid #3f4248;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    transition: 0.5s ease;
    &:hover {
        background: #0264d8;
        box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06),
            0px 16px 17.87px rgba(0, 109, 255, 0.08),
            0px 8px 33.42px rgba(0, 109, 255, 0.12),
            0px 33px 80px rgba(0, 109, 255, 0.16);
    }
    p {
        margin: 0;
        padding: 0;
        text-decoration: none;
        list-style: none;
    }
}
.bFont {
    font-size: 15px;
}
</style>
